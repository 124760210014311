<template>
    <div>
        <el-table :data="dataList" style="font-size: 12px;" empty-text="No Data" :highlight-current-row="false"
            :highlight-selection-row="false" :header-cell-class-name="'custom-head-cell'" :cell-style="tableCellstyle"
            :row-class-name="'custom-row-bg'">
            <el-table-column prop="category.name" label="Category"></el-table-column>
            <el-table-column prop="award_title" label="Feature Award"></el-table-column>
            <el-table-column prop="" label="Application Type">
                <template slot-scope="scope">
                    {{ scope.row.application_type == '个人' ? 'Individual' : 'Team' }}
                </template>
            </el-table-column>
            <el-table-column prop="" label="Entry Type">
                <template slot-scope="scope">
                    {{ ENTRY_TYPES[scope.row.entry_type] }}
                </template>
            </el-table-column>
            <el-table-column prop="team_type" label="Team Type"></el-table-column>
            <el-table-column prop="" label="Apply" width="240px">
                <template slot-scope="scope">
                    <el-button type="success" size="small" @click="doApplyEntry(scope.row)">Apply</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
import {
    getFeatureAwards
} from '../api/eae'
import {
    ENTRY_TYPES
} from '../utils/store'
export default {
    name: 'index',
    data() {
        return {
            ENTRY_TYPES,
            dataList: []
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        fetchData() {
            getFeatureAwards().then((res) => {
                this.dataList = res.data
            })
        },
        doApplyEntry(feature_award) {
            this.$emit('handleApplyFeatureAward', feature_award)
        },
        tableCellstyle() {
            return 'background:  #FAE3E4;border:1px solid white;text-align:center; font-family: Ubuntu-Bold;'
        }
    }
}
</script>

<style scoped lang="scss">
/deep/ .el-table .custom-row-bg {
    background: #FAE3E4;
}

/deep/ .el-table .custom-head-cell {
    background: var(--c-primary);
    color: white;
    text-align: center;
    border: 1px solid white;
    box-sizing: border-box;
    font-family: Ubuntu-Bold;
}
</style>