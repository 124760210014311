<template>
  <div class="activity-container p-6 bg-white my-4 flex items-center justify-between"
    :style="{ backgroundColor: showEntryStatus == 'complete' ? '#FFF2CC' : '#ffffff' }">
    <div class="flex" style="flex: 1; width: 100%; padding-right: 10px; box-sizing: border-box">
      <div class="activity-info">
        <div style="display: flex;">
          <p class="activity-name text-base mb-4" style="background-color:  #FFF2CC;">{{
            FORMAT_PROJECT[info.projectManage.project_code]
            }}</p>
          <p class="activity-name text-base mb-4" style="margin-left: 15px;background-color: #FFD9D4;">{{
            info.application_type == '个人' ? 'Individual Application' : 'Team Application' }}</p>

          <p v-if="info.feature_award" class="activity-name text-base mb-4"
            style="margin-left: 15px;color: white;background-color: #FF8E3B;">{{
              info.feature_award.award_title }}</p>
        </div>
        <p class="activity-title text-base mb-4">
          {{ info.title }}
        </p>
        <div class="activity-time flex text-sm">
          <p><span class="font-bold">Entry Type:&nbsp;</span>{{ formatEntryType(info.entry_type) }}</p>
          <p>|</p>
          <p><span class="font-bold">Category:&nbsp;</span>{{ info.type.name }}</p>
          <p v-if="info.application_type == '团队'">|</p>
          <p v-if="info.application_type == '团队'"><span class="font-bold">Team Type:&nbsp;</span>{{ info.team_type }}
          </p>
        </div>
        <div class="flex" style="margin-top: 10px;display: flex;flex-wrap: wrap;gap: 5px;">
          <div v-for="(tag, index) in tags" :key="index">
            <p style="color: #333;font-size: 14px;">#{{ tag }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col flex-wrap-reverse" v-if="showEntryStatus == 'edit'">
      <div class="flex items-center">
        <div class="mr-2 flex-1" style="font-size: 12px; color: #666" v-if="!info.submission_date">
          <div class="text-right">The submission deadline for next review cycle is</div>
          <div class="text-right">
            {{ formatNextCycleDate() }}
          </div>
        </div>
        <div class="btn-edit" @click="handleEdit">
          {{ 'Edit' }}
        </div>
        <div class="btn-edit" v-if="!info.submission_date && info.application_type == '个人'" @click="doSubmit">
          {{ 'Submit' }}
        </div>
        <div class="btn-edit" v-if="!info.submission_date && info.application_type == '团队' && user_id == info.user_id"
          @click="doSubmit">
          {{ 'Submit' }}
        </div>
      </div>
      <div class="btn-edit" style="margin-top: 10px;" v-if="user_id == info.user_id" @click="handleRemove">Delete</div>
      <div class="btn-edit" style="margin-top: 10px;" v-else-if="user_id != info.user_id" @click="handleQuit">Quit</div>
      <div class="btn-edit" style="margin-top: 10px;" v-if="user_id == '61cd6856a4640b946f8b456f'"
        @click="gotoShowCert">查看证书</div>
    </div>
    <div class="flex flex-col flex-wrap-reverse" v-else-if="showEntryStatus == 'under_review'">
      <div class="flex items-center">
        <div class="mr-2 flex-1" style="font-size: 12px; color: #666">
          <div class="text-right">Check result on</div>
          <div class="text-right">
            {{ formatAnnounceTime() }}
          </div>
        </div>
        <div class="btn-under-review">
          {{ 'Under Review' }}
        </div>
        <div class="btn-edit" style="margin-top: 10px;" v-if="user_id == '61cd6856a4640b946f8b456f'"
          @click="gotoShowCert">查看证书</div>
        <div class="btn-edit" style="margin-top: 10px;" v-if="user_id == '61cd6856a4640b946f8b456f'"
          @click="gotoShowResult">Transcript</div>
      </div>
    </div>
    <div class="flex flex-col flex-wrap-reverse" v-else>
      <div>
        <div class="btn-result" @click="gotoShowResult">Transcript</div>
        <div class="btn-result" v-if="showAward == false" style="margin-top: 10px;"> {{ 'Not Awarded' }}</div>
        <div class="btn-result" v-else @click="gotoShowCert" style="margin-top: 10px;"> {{ 'Certificate' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ENTRY_TYPES, FORMAT_PROJECT } from "../utils/store";
import {
  getUserId
} from '../utils/store'
export default {
  name: "ActivityEntry",
  props: {
    info: Object,
    index: Number,
  },
  watch: {
    info: {
      handler(newVal, oldVal) {
        this.handleEntryInfo()
      },
      immediate: true
    }
  },
  data() {
    return {
      FORMAT_PROJECT,
      user_id: getUserId(),
      showEntryStatus: '',//edit ,under_review ,complete
      showAward: false,
      tags: [],
      team_submission_time: 0,
      indie_submission_time: 0,

      team_announce_time: 0,
      indie_announce_time: 0,
    }
  },
  methods: {


    formatNextCycleDate() {
      let current_time = parseInt(new Date().getTime() / 1000)
      let entry_date = this.info.projectManage.entry_date
      entry_date.sort(function (a, b) {
        return a.ddl_date - b.ddl_date
      })
      let next_ddl_date = -1;
      let one_day = 24 * 60 * 60 * 1000 - 1
      for (let i = 0; i < entry_date.length; i++) {
        let item = entry_date[i]
        let ddl_date = 0
        ddl_date = parseInt((item.ddl_date + one_day) / 1000)
        if (item.app_type == 'indie') {
          if (current_time < ddl_date) {
            next_ddl_date = ddl_date
            break
          }
        }
        if (item.app_type == 'team') {
          if (current_time < ddl_date) {
            next_ddl_date = ddl_date
            break;
          }
        }
      }
      return this.convertTimeToEng(next_ddl_date)

    },

    gotoShowResult() {
      this.$router.push({
        path: '/newScore',
        // path: getUserId() == '61cd6856a4640b946f8b456f' ? '/home/newScore' : '/home/score',
        query: {
          id: this.info._id.$id
        }
      })
    },

    gotoShowCert() {
      let url = this.$router.resolve({
        path: '/certificate',
        query: {
          id: this.info._id.$id
        }
      })
      window.open(url.href, '_blank')
    },

    handleEntryInfo() {
      this.tags = []
      this.showEntryStatus = 'edit'
      this.showAward = false
      if (this.info.tags && this.info.tags != '') {
        this.tags = this.info.tags.split(',')
      }
      //把项目配置时间都取出来
      this.formatProjectConfigTime()

      let submission_date = this.info.submission_date//单位秒

      //编辑的截止时间
      let submission_time = 0
      //公布时间
      let announce_time = 0
      if (this.info.application_type == '个人') {
        submission_time = this.indie_submission_time
        announce_time = this.indie_announce_time
      } else {
        submission_time = this.team_submission_time
        announce_time = this.team_announce_time
      }

      if (submission_time <= 0 || announce_time <= 0) {
        return;
      }
      let current_time = parseInt(new Date().getTime() / 1000)

      if (!submission_date) {
        return
      }
      if (current_time < announce_time) {
        this.showEntryStatus = 'under_review'
        return
      }

      this.showEntryStatus = 'complete'

      if (this.info.score) {
        let entryScore = 0
        let bonus_points = this.info.score.bonus_points
        let scoring_dimension = this.info.score.scoring_dimension
        let keys = Object.keys(scoring_dimension)
        for (let i = 0; i < keys.length; i++) {
          let score = scoring_dimension[keys[i]]
          entryScore = entryScore + parseInt(score)
        }
        let totalScore = entryScore + bonus_points
        if (totalScore >= 50) {
          this.showAward = true
        }
      }
    },

    formatProjectConfigTime() {

      this.indie_submission_time = -2
      this.indie_announce_time = -2

      this.team_submission_time = -2
      this.team_announce_time = -2

      if (!this.info.submission_date) {
        return;
      }
      let submission_date = this.info.submission_date//单位秒

      let entry_date = this.info.projectManage.entry_date
      entry_date.sort(function (a, b) {
        return a.ddl_date - b.ddl_date
      })
      //entry的创建时间
      // let current_time = parseInt(new Date().getTime() / 1000)
      // let create_time = this.info.create_time

      let one_day = 24 * 60 * 60 * 1000 - 1

      let indi_index = -1
      let team_index = -1

      for (let i = 0; i < entry_date.length; i++) {
        let item = entry_date[i]
        let ddl_date = 0
        let announce_date = 0

        ddl_date = parseInt((item.ddl_date + one_day) / 1000)
        announce_date = parseInt((item.announce_date) / 1000)

        // if (item.app_type == 'indie') {
        //   indi_index++
        // } else {
        //   team_index++
        // }
        if (item.app_type == 'indie' && this.indie_submission_time == -2) {//个人
          if (submission_date < ddl_date) {//如果create_time > announce_date 
            this.indie_submission_time = ddl_date
            this.indie_announce_time = announce_date
            // if (indi_index > 0) {
            //   let last_item = entry_date[indi_index - 1]
            //   let last_announce_date = parseInt((last_item.announce_date) / 1000)
            //   if (create_time < last_announce_date) {
            //     this.indie_submission_time = -1
            //     this.indie_announce_time = -1
            //   }
            // }
          }
        }
        //团队
        if (item.app_type == 'team' && this.team_submission_time == -2) {
          if (submission_date < ddl_date) {
            this.team_submission_time = ddl_date
            this.team_announce_time = announce_date
            // if (team_index > 0) {
            //   let last_item = entry_date[team_index - 1]
            //   let last_announce_date = parseInt((last_item.announce_date) / 1000)
            //   if (create_time < last_announce_date) {
            //     this.team_submission_time = -1
            //     this.team_announce_time = -1
            //   }
            // }
          }
          // else {
          //   this.team_submission_time = ddl_date
          //   this.team_announce_time = announce_date
          // }
        }
      }
      console.log('indie_submission_time :' + this.indie_submission_time)
      console.log('indie_announce_time :' + this.indie_announce_time)
      console.log('team_submission_time :' + this.team_submission_time)
      console.log('team_announce_time :' + this.team_announce_time)
    },

    //格式化时间公布时间
    formatAnnounceTime() {
      let announce_time = 0
      if (this.info.application_type == '个人') {
        announce_time = this.indie_announce_time
      } else {
        announce_time = this.team_announce_time
      }
      return this.convertTimeToEng(announce_time)
    },
    //格式化截止时间
    formatDllTime() {
      let dll_time = 0
      if (this.info.application_type == '个人') {
        dll_time = this.indie_submission_time
      } else {
        dll_time = this.team_submission_time
      }
      return this.convertTimeToEng(dll_time)
    },
    formatEntryType(entry_type) {

      if (entry_type == "team_story" || entry_type == "indie_story") {
        return 'Storyline'

      } else if (entry_type == "team_exp" || entry_type == "indie_exp") {
        return 'Single Activity'
      }

      return ENTRY_TYPES[entry_type];
    },

    doSubmit() {
      this.$emit("submitDate");
    },

    handleQuit() {
      this.$confirm(
        "Are you sure you want to quit from this team application?",
        "Quit Entry",
        {
          type: "warning",
          confirmButtonText: "Confirm",
        }
      )
        .then(() => {
          let info = {
            entry_id: this.info._id.$id,
            entry_people_id: this.info.entry_people_id
          }
          this.$emit("quitEntry", info);
        })
        .catch(() => {
        });
      return
    },

    handleEdit() {
      this.$emit("edit");
    },
    handleRemove() {
      this.$confirm(
        "Are you sure you want to delete this entry?",
        "Delete Entry",
        {
          type: "warning",
          confirmButtonText: "Confirm",
        }
      )
        .then(() => {
          this.$emit("remove");
        })
        .catch(() => {
        });
    },
    handleStandards() {
      this.$emit("standards");
    },

    convertTimeToEng(time) {
      var date = new Date(time * 1000);
      let year = date.getFullYear();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      return this.formatMonthToEng(month) + " " + day + ", " + year;
    },

    formatMonthToEng(month) {
      switch (month) {
        case 1:
          return "January";
        case 2:
          return "February";
        case 3:
          return "March";
        case 4:
          return "April";
        case 5:
          return "May";
        case 6:
          return "June";
        case 7:
          return "July";
        case 8:
          return "August";
        case 9:
          return "September";
        case 10:
          return "October";
        case 11:
          return "November";
        case 12:
          return "December";
      }
    },



  },
};
</script>

<style lang="scss" scoped>
.btn-under-review {
  width: 110px;
  text-align: center;
  background-color: transparent;
  border-radius: 5px;
  border: 1px #FAA104 solid;
  padding: 12px 0px;
  box-sizing: border-box;
  color: #333333;
  font-size: 15px;
}

.btn-result {
  width: 110px;
  text-align: center;
  background-color: transparent;
  border-radius: 5px;
  border: 1px #FAA104 solid;
  padding: 12px 0px;
  box-sizing: border-box;
  color: #FAA104;
  font-size: 15px;
  cursor: pointer;
}

.btn-edit {
  width: 110px;
  text-align: center;
  background-color: #ff6450;
  border-radius: 5px;
  padding: 12px 0px;
  box-sizing: border-box;
  color: white;
  font-size: 15px;
  cursor: pointer;
}


.activity-container {

  .sort {
    font-size: 16px;
    padding: 2px 8px;
    color: var(--c-primary);
    background-color: #ffefed;
    height: fit-content;
  }

  .activity-info {
    .activity-name {
      color: #333333;
    }

    .activity-title {
      color: var(--c-primary);
      font-weight: bold;
      font-size: 24px;
    }

    .activity-time {
      color: #333333;

      p {
        margin-right: 5px;
      }
    }
  }
}
</style>
